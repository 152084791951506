import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui';
import i18n from '@/lang'
import "./core/config"
import 'element-ui/lib/theme-chalk/index.css';
import '@/assets/css/style.css'
import VueCountryCode from "vue-country-code";
import VueClipboard from 'vue-clipboard2' //引入插件
// 引入echarts
import echarts from 'echarts'
Vue.prototype.$echarts = echarts

import BaiduMap from 'vue-baidu-map'
Vue.use(BaiduMap, {
  // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
  ak: 'xAVgDPkXptinExaHY1mQxNbpz8M74PYA'
})






Vue.use(VueClipboard) //安装插件
Vue.config.productionTip = false
Vue.use(ElementUI, {
  i18n: (key: string, value: string) => i18n.t(key, value)
})
//底部tabbar事件总线
Vue.prototype.eventBus = new Vue()

Vue.use(VueCountryCode);

Vue.directive('loadmore', {
  bind(el, binding) {
    var p = 0;
    var t = 0;
    var down = true;
    var selectWrap = el.querySelector('.el-table__body-wrapper')
    if (selectWrap !== null) {
      selectWrap.addEventListener('scroll', function () {
        //判断是否向下滚动
        //@ts-ignore
        p = this.scrollTop;
        // if ( t < p){down=true}else{down=false}
        if (t < p) {
          down = true;
        } else {
          down = false;
        }
        t = p;
        //判断是否到底
        const sign = 10;
        //@ts-ignore
        const scrollDistance = this.scrollHeight - this.scrollTop - this.clientHeight
        if (scrollDistance <= sign && down) {
          binding.value()
        }
      })
    }
  }
})

// router.beforeEach((to,from,next) => {
//   if(to.meta.title){
//     document.title = to.meta.title
//   }
//   next()
// })


// Vue.directive('title', {
//   inserted: function (el, binding) {
//     document.title = el.dataset.title || 'Pentax'
//   }
// })

new Vue({
  i18n,
  router,
  render: h => h(App)
}).$mount('#app')
