import format from 'date-fns/format';

export default class Utils {
    static getDateFormatTime(date: number) {
        if (date) {
            return String(format(date, 'yyyy-MM-dd HH:mm:ss'))
        } else {
            // return '--'
        }

    }

    static getDateFormatTimeSecond(date: number) {
        if (date) {
            return String(format(date, 'yyyy-MM-dd'))
        } else {
            // return '--'
        }

    }

}