import http from "./http";

function request({ method, url, data = {}, params = {} }) {
    return http({
        method,
        url,
        data,
        params
    })
}

export default request