import Vue from 'vue'
import i18n from '@/lang'
import VueRouter, { RouteConfig } from 'vue-router'
import Home from '../views/Home.vue'
import supplier from "../components/LangSelect/supplierAllBack.vue";
import Login from '../views/Login.vue'
import Register from '../views/Register.vue'
import PhoneBind from '../views/PhoneBind.vue'
import SetPassword from '../views/SetPassword.vue'
import Personal from '../views/Personal.vue'
import EditName from '../views/EditName.vue'
import EditEmail from '../views/EditEmail.vue'
import EditPassword from '../views/EditPassword.vue'
import DeviceInfo from '../views/DeviceInfo.vue'
import EditDeviceName from '../views/EditDeviceName.vue'
import DeviceLocation from '../views/DeviceLocation.vue'
import DeviceAlert from '../views/DeviceAlert.vue'
import DeviceLog from '../views/DeviceLog.vue'
import DeviceErrorLog from '../views/DeviceErrorLog.vue'
import Feedback from '../views/Feedback.vue'
import About from '../views/About.vue'
import DeviceInstructions from '../views/DeviceInstructions.vue'
import DeviceProblem from '../views/DeviceProblem.vue'
import Addscene from '../views/Addscene.vue'
import sceneIMV from '../views/sceneIMV/event-device.vue'
import eventDetail from '../views/sceneIMV/eventDetail.vue'
import execute from '../views/sceneIMV/execute-device.vue'
import valveList from '../views/sceneIMV/ValveList.vue'
import Finally from '../views/sceneIMV/Finally.vue'
import currentState from '../views/currentState/currentState.vue'
import wateroutState from '../views/wateroutState/wateroutState.vue'
import AddPeople from '../components/listDetail/AddPeople.vue'
import ManyPeople from '../components/listDetail/ManyPeople.vue'
import deviceName from '../components/listDetail/deviceName.vue'
import deviceAlert from '../components/listDetail/deviceAlert.vue'
import deviceLocation from '../components/listDetail/deviceLocation.vue'
import deviceLog from '../components/listDetail/deviceLog.vue'
import deviceEncyclopedia from '../components/listDetail/deviceEncyclopedia.vue'
import WaterData from '../components/listDetail/WaterData.vue'
import WaterModel from '../components/listDetail/WaterModel.vue'
import WaterRegularly from '../components/listDetail/WaterRegularly.vue'
import parametricModel from '../components/listDetail/parametricModel.vue'
import NotificationSettings from '../components/listDetail/NotificationSettings.vue'
import ConnectedState from '../components/listDetail/ConnectedState.vue'
import version from '../components/listDetail/version.vue'
import LeakageTest from '../../src/components/listDetail/LeakageTest.vue'
import calibration from '../components/listDetail/calibration.vue'
import shop from '../components/shop/shop.vue'
import Checkout from '../components/listDetail/Checkout.vue'
import location from '../components/listDetail/Location/location.vue'
import AddAccount from '../components/listDetail/AddAccount/AddAccount.vue'
import ListManyPeople from '../components/listDetail/ListManyPeople/ListManyPeople.vue'
import AddDevices from '@/components/AddDevices/AddDevices.vue'
import Guide from '@/components/AddDevices/Guide.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [

  //我的设备
  {
    path: '/',
    name: 'Main',
    component: Home,
    meta: {
      title: i18n.t('home.title')
    }
  },

  //我的设备
  {
    path: '/home',
    name: 'Home',
    component: Home,
    meta: {
      title: i18n.t('home.title')
    }
  },
  //添加配网设备
  {
    path: '/AddDevices',
    name: 'AddDevices',
    component: AddDevices,
    meta: {
      title: i18n.t('addDevice.set_up_net')
    }
  },

  //新增场景联动页面
  {
    path: '/addscene',
    name: 'Addscene',
    component: Addscene,

    children: [
      {
        path: 'mayAdd',
        name: 'mayAdd',
        component: () => import('../components/linkage/mayAdd.vue'),
        meta: {
          title: i18n.t('linkage.can_add_scenario_linkage')
        },
      },
      {
        path: 'alreadyAdd',
        name: 'alreadyAdd',
        component: () => import('../components/linkage/alreadyAdd.vue'),
        meta: {
          title: i18n.t('linkage.can_add_scenario')
        },
      },
      {
        path: '/addscene',
        redirect: 'mayAdd'
      }
    ]
  },




  //用水数据
  {
    path: '/WaterData',
    name: 'WaterData',
    component: WaterData,
    meta: {
      title: i18n.t('WaterData.title')
    },
  },




  //选择语言
  {
    path: '/supplier',
    name: 'supplier',
    component: supplier,
  },

  //添加账号
  {
    path: '/ListManyPeople',
    name: 'ListManyPeople',
    component: ListManyPeople,
    meta: {
      title: i18n.t('ListManyPeople.AddAccount')
    },
  },

  //添加账号
  {
    path: '/AddAccount',
    name: 'AddAccount',
    component: AddAccount,
    meta: {
      title: i18n.t('AddAccount.AddAccount')
    },
  },

  //生产检验
  {
    path: '/Checkout',
    name: 'Checkout',
    component: Checkout,
    meta: {
      title: i18n.t('checkout.title')
    },
  },

  //参数校准
  {
    path: '/calibration',
    name: 'calibration',
    component: calibration,
    meta: {
      title: i18n.t('calibration.title')
    },
  },

  //渗漏测试
  {
    path: '/LeakageTest',
    name: 'LeakageTest',
    component: LeakageTest,
    meta: {
      title: i18n.t('leakage.title')
    },
  },

  //地理位置
  {
    path: '/deviceLocation',
    name: 'deviceLocation',
    component: deviceLocation,
    meta: {
      title: i18n.t('location.title1')
    },
  },

  //监护人通知列表
  {
    path: '/AddPeople',
    name: 'AddPeople',
    component: AddPeople,
    meta: {
      title: i18n.t('noticeSet.GuardianNotificationList')
    },
  },


  //地址定位
  {
    path: '/location',
    name: 'location',
    component: location,
    meta: {
      title: i18n.t('location.title2')
    },
  },


  //选择条件
  {
    path: '/sceneIMV',
    name: 'sceneIMV',
    component: sceneIMV,
    meta: {
      title: i18n.t('sceneIMV.SelectionCriteria')
    },
  },

  //水路当前状态
  {
    path: '/currentState',
    name: 'currentState',
    component: currentState,
    meta: {
      title: i18n.t('currentState.current')
    },
  },

  //水浸当前状态
  {
    path: '/wateroutState',
    name: 'wateroutState',
    component: wateroutState,
    meta: {
      title: i18n.t('wateroutState.current')
    },
  },

  {
    path: '/eventDetail',
    name: 'eventDetail',
    component: eventDetail,
  },

  //选择执行
  {
    path: '/execute',
    name: 'execute',
    component: execute,
    meta: {
      title: i18n.t('sceneIMV.SelectExecute')
    },
  },

  {
    path: '/valveList',
    name: 'valveList',
    component: valveList,

  },

  //新建联动
  {
    path: '/Finally',
    name: 'Finally',
    component: Finally,
    meta: {
      title: i18n.t('sceneIMV.NewLinkage')
    },
  },

  //购买方式
  {
    path: '/shop',
    name: 'shop',
    component: shop,
    meta: {
      title: i18n.t('Shoping.Address')
    },
  },

  //多人通知列表
  {
    path: '/ManyPeople',
    name: 'ManyPeople',
    component: ManyPeople,
    meta: {
      title: i18n.t('noticeSet.MultiPersonNotificationList')
    },
  },

  //修改设备名称
  {
    path: '/deviceName',
    name: 'deviceName',
    component: deviceName,
    meta: {
      title: i18n.t('reviseDeviceName.reviseDeviceName')
    },
  },

  //报警接收设置
  {
    path: '/deviceAlert',
    name: 'deviceAlert',
    component: deviceAlert,
    meta: {
      title: i18n.t('deviceAlert.AlarmReceivingSettings')
    },
  },

  //设备日志
  {
    path: '/deviceLog',
    name: 'deviceLog',
    component: deviceLog,
    meta: {
      title: i18n.t('device_log.title')
    },
  },

  //设备百科
  {
    path: '/deviceEncyclopedia',
    name: 'deviceEncyclopedia',
    component: deviceEncyclopedia,
    meta: {
      title: i18n.t('deviceEncyclopedia.title')
    },
  },

  //用水模型
  {
    path: '/WaterModel',
    name: 'WaterModel',
    component: WaterModel,
    meta: {
      title: i18n.t('waterModel.title')
    },
  },

  //定时用水
  {
    path: '/WaterRegularly',
    name: 'WaterRegularly',
    component: WaterRegularly,
    meta: {
      title: i18n.t('timingWater.title')
    },
  },

  //参数模式
  {
    path: '/parametricModel',
    name: 'parametricModel',
    component: parametricModel,
    meta: {
      title: i18n.t('parametricModel.title')
    },
  },

  //通知设置
  {
    path: '/NotificationSettings',
    name: 'NotificationSettings',
    component: NotificationSettings,
    meta: {
      title: i18n.t('noticeSet.NotificaSettings')
    },
  },

  //联网状态
  {
    path: '/ConnectedState',
    name: 'ConnectedState',
    component: ConnectedState,
    meta: {
      title: i18n.t('ListManyPeople.AddAccount')
    },
  },

  //设备重置
  {
    path: '/Guide',
    name: 'Guide',
    component: Guide,
    meta: {
      title: i18n.t('AddDevices.DeviceReset')
    },
  },


  //固件版本
  {
    path: '/version',
    name: 'version',
    component: version,
    meta: {
      title: i18n.t('version.title')
    },
  },


  //手机号密码登录
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      title: i18n.t('login.title')
    }
  },

  //注册账号
  {
    path: '/register',
    name: 'register',
    component: Register,
    meta: {
      title: i18n.t('Register.title')
    }
  },

  //手机验证码登录
  {
    path: '/phonebind',
    name: 'phonebind',
    component: PhoneBind,
    meta: {
      title: i18n.t('PhoneBind.title')
    }
  },

  //设置密码
  {
    path: '/setPassword',
    name: 'setPassword',
    component: SetPassword,
    meta: {
      title: i18n.t('set_password.title')
    }
  },

  //绑定邮箱
  {
    path: '/editemail',
    name: 'editemail',
    component: EditEmail,
    meta: {
      title: i18n.t('edit_email.title')
    }
  },

  //密码修改
  {
    path: '/editpassword',
    name: 'editpassword',
    component: EditPassword,
    meta: {
      title: i18n.t('edit_password.title')
    }
  },

  //个人中心
  {
    path: '/personal',
    name: 'personal',
    component: Personal,
    meta: {
      title: i18n.t('personal.title')
    }
  },

  //修改昵称
  {
    path: '/editname',
    name: 'editname',
    component: EditName,
    meta: {
      title: i18n.t('edit_name.title')
    }
  },

  //我的设备
  {
    path: '/deviceinfo',
    name: 'deviceinfo',
    component: DeviceInfo,
    meta: {
      title: i18n.t('device_info.title')
    }
  },

  //修改设备名称
  {
    path: '/editdevicename',
    name: 'editdevicename',
    component: EditDeviceName,
    meta: {
      title: i18n.t('edit_device_name.title')
    }
  },

  //修改地理位置信息
  {
    path: '/devicelocation',
    name: 'devicelocation',
    component: DeviceLocation,
    meta: {
      title: i18n.t('device_location.title')
    }
  },

  //报警信息
  {
    path: '/devicealert',
    name: 'devicealert',
    component: DeviceAlert,
    meta: {
      title: i18n.t('device_alert.title')
    }
  },

  //设备日志
  {
    path: '/devicelog',
    name: 'devicelog',
    component: DeviceLog,
    meta: {
      title: i18n.t('device_log.title')
    }
  },

  //设备异常日志
  {
    path: '/errorlog',
    name: 'errorlog',
    component: DeviceErrorLog,
    meta: {
      title: i18n.t('device_error_log.title')
    }
  },

  //问题反馈
  {
    path: '/feedback',
    name: 'feedback',
    component: Feedback,
    meta: {
      title: i18n.t('feedback.title')
    }
  },

  //关于设备
  {
    path: '/about',
    name: 'about',
    component: About,
    meta: {
      title: i18n.t('about.title')
    }
  },

  //说明书
  {
    path: '/instructions',
    name: 'instructions',
    component: DeviceInstructions,
    meta: {
      title: i18n.t('device_instructions.title')
    }
  },

  //异常处理
  {
    path: '/deviceproblem',
    name: 'deviceproblem',
    component: DeviceProblem,
    meta: {
      title: i18n.t('device_problem.title')
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// 用导航守卫检测token
router.beforeEach(async (to, from, next) => {

  if (to.path == '/register' || to.path == '/login' || to.path == '/phonebind' || to.path == '/forgetpassword') {
  } else {
    const token = localStorage.getItem('token')
    if (token && token !== '' && token !== undefined) {
      next();
    } else {
      next({
        path: '/login',
      })
    }
  }
  if (to!.meta!.title) {
    document.title = to!.meta!.title
  }
  next();

})

export default router
