import axios from "axios";
//本地路径
// let baseURL = 'http://192.168.18.214:9012'
// let baseURL = 'http://apiiotft.bintaike.cn'
let baseURL = 'https://apiiot.bintaike.cn'
//请求路径
const http = axios.create({
    baseURL: baseURL,
    headers: {
        'X-LANG': localStorage.getItem("lang") || "en-US",
        "content-type": "application/json;charset=UTF-8"
    }
})
//请求拦截器
http.interceptors.request.use(config => {
    //解决登录之后  首页数据请求头中没有token  后端收不到请求
    if (config.url == '/api/v1/abroad/client/account/login') {
    } else {
        config.headers = {
            'X-AUTH-TOKEN': window.localStorage.getItem("token"),
        }
    }
    return config
}, error => {
    console.log("请求拦截器error", error)
})

export default http