import Vue from 'vue'
import VueI18n from 'vue-i18n'


// element-ui built-in lang
import elementEnLocale from 'element-ui/lib/locale/lang/en'
import elementZhLocale from 'element-ui/lib/locale/lang/zh-CN'
import elementEsLocale from 'element-ui/lib/locale/lang/es'
import elementItLocale from 'element-ui/lib/locale/lang/it'
import elementFrLocale from 'element-ui/lib/locale/lang/fr'
import elementRuLocale from 'element-ui/lib/locale/lang/ru-RU'
import elementJaLocale from 'element-ui/lib/locale/lang/ja'
import elementKoLocale from 'element-ui/lib/locale/lang/ko'

// User defined lang
const zhLocale = require("./zh.json")
const enLocale = require("./en.json")
const esLocale = require("./es.json")
const itLocale = require("./it.json")
const frLocale = require("./fr.json")
const ruLocale = require("./ru.json")
const jaLocale = require("./ja.json")
const koLocale = require("./ko.json")
// import enLocale from './en'
// import zhLocale from './zh'
// import esLocale from './es'
// import itLocale from './it'
// import frLocale from './fr'
// import ruLocale from './ru'
// import jaLocale from './ja'
// import koLocale from './ko'


Vue.use(VueI18n)

const messages = {
  en: {
    ...enLocale,
    ...elementEnLocale
  },
  zh: {
    ...zhLocale,
    ...elementZhLocale
  },
  es: {
    ...esLocale,
    ...elementEsLocale
  },
  it: {
    ...itLocale,
    ...elementItLocale
  },
  fr: {
    ...frLocale,
    ...elementFrLocale
  },
  ru: {
    ...ruLocale,
    ...elementRuLocale
  },
  jp: {
    ...jaLocale,
    ...elementJaLocale
  },
  kr: {
    ...koLocale,
    ...elementKoLocale
  },
}

export const getLocale = () => {
  const cookieLanguage = localStorage.getItem("lang")
  if (cookieLanguage) {
    document.documentElement.lang = cookieLanguage
    return cookieLanguage
  }

  const language = navigator.language.toLowerCase()
  const locales = Object.keys(messages)
  for (const locale of locales) {
    if (language.indexOf(locale) > -1) {
      document.documentElement.lang = locale
      let lang = 'en-US'
      if (locale === 'zh') { lang = 'zh-CN' }
      if (locale === 'en') { lang = 'en-US' }
      if (locale === 'es') { lang = 'es-ES' }
      if (locale === 'it') { lang = 'it-IT' }
      if (locale === 'fr') { lang = 'fr-FR' }
      if (locale === 'ru') { lang = 'ru-RU' }
      if (locale === 'jp') { lang = 'jp-JP' }
      if (locale === 'kr') { lang = 'kr-KR' }
      localStorage.setItem("lang", lang)
      return locale
    }
  }

  // Default language is english
  localStorage.setItem("lang", 'en-US')
  return 'en-US'
}

const i18n = new VueI18n({
  locale: getLocale(),// 语言标识
  fallbackLocale: 'en-US',//默认语言
  silentFallbackWarn: true,//抑制警告
  messages
})

export default i18n
