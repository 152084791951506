import { Options as APIOptions} from '@ym-framework/api-utils';


APIOptions.host.set('base.auth', async () => {

    return {
        baseURL: "https://apiiotft.bintaike.cn/",
        // baseURL: "http://192.168.18.214:9012/",
        headers: {
            'X-AUTH-TOKEN': localStorage.getItem("token"),
            'X-LANG': localStorage.getItem("lang") || "en-US",
        }
    }
})

APIOptions.host.set('base', () => {
    return {
        baseURL: "https://apiiotft.bintaike.cn/",
        // baseURL: "http://192.168.18.214:9012/",
        headers: {
            'X-LANG': localStorage.getItem("lang") || "en-US",
        }
    }
});


