import request from "./request";

/**
 * 注意：
 *       参数params是跟在请求路径后面
 *       参数data是传参
 */



//海外版注册接口
export const putRegister = (data) => request({ method: 'put', url: '/api/v1/abroad/account/register', data })

//海外版登录接口
export const PostLogin = (data) => request({ method: 'post', url: '/api/v1/abroad/client/account/login', data })

//海外版获取验证码
export const PostPhoneVerify = (data) => request({ method: 'post', url: '/api/v1/abroad/short_message_verify_code', data })

//海外版验证码登录
export const PostloginPhoneVerify = (data) => request({ method: 'post', url: '/api/v1/abroad/client/account/login_phoneVerify', data })

//海外版用户设备注册
export const PutDeviceRegister = (data) => request({
    method: 'put',
    url: '/api/v1/abroad/account/valve_device/register',
    data
})



//用户设备列表
export const GetDevices = (params) => request({ method: 'get', url: '/api/v1/wechat/account/devices', params })

//用户设备详情
export const GetDevicesDetail = (flag, id) => request({ method: 'get', url: `/api/v1/wechat/account/product_flag/${flag}/device/${id}` })

//用户个人中心
export const GetSession = () => request({ method: 'get', url: '/api/v1/client/account/session' })

//阀门开关状态
export const PutSwitch = (flag, id) => request({ method: 'put', url: `/api/v1/wechat/account/device/${flag}/valve/switch/${id}` })

//设备展示页
export const GetShowPageList = (product_flag, device_id) => request({ method: 'get', url: `/api/v1/wechat/${product_flag}/${device_id}/showpage` })

//设备展示页 ---- 模式选择
export const PutModelEdit = (data) => request({ method: 'put', url: '/api/v1/wechat/valve/showpage/model/edit', data })

//修改设备名称 /api/v1/wechat/account/product_flag/{product_flag}/device/{device_id}/device_name
export const PutDeviceName = (product_flag, device_id, data) => request({ method: 'put', url: `/api/v1/wechat/account/product_flag/${product_flag}/device/${device_id}/device_name`, data })

//修改地理位置PUT	/api/v1/wechat/account/product_flag/{product_flag}/device/{device_id}/location
export const PutLocationName = (product_flag, device_id, data) => request({ method: 'put', url: `/api/v1/wechat/account/product_flag/${product_flag}/device/${device_id}/location`, data })

//查询参数模式设定/api/v1/wechat/account/device/{device_id}/paramset
export const GetParamSet = (device_id) => request({ method: 'get', url: `/api/v1/wechat/account/device/${device_id}/paramset` })
//参数模式设定/api/v1/wechat/account/device/{device_id}/paramset
export const PutParamSet = (device_id, data) => request({ method: 'put', url: `/api/v1/wechat/account/device/${device_id}/paramset`, data })

//参数模式恢复默认状态/api/v1/wechat/account/device/{device_id}/paramset/default
export const PutDefault = (device_id) => request({ method: 'put', url: `/api/v1/wechat/account/device/${device_id}/paramset/default` })

//水路查询通知设置PUT	/api/v1/wechat/account/{product_flag}/device/{device_id}/notice
export const GetNotice = (product_flag, device_id) => request({ method: 'get', url: `/api/v1/abroad/account/${product_flag}/device/${device_id}/notice` })

//通知设置
export const PutNotice = (product_flag, device_id, data) => request({ method: 'put', url: `/api/v1/abroad/account/${product_flag}/device/${device_id}/notice`, data })

//水浸报警接收
export const GetWaterNotice = (device_name) => request({ method: 'get', url: `/api/v1/client/device/notice/${device_name}` })
export const PutWaterNotice = (device_name, data) => request({ method: 'put', url: `/api/v1/client/device/notice/${device_name}`, data })




/**
 * 定时用水设置简化版
 * 1.0定时用水设置列表 GET	/api/v1/wechat/valve/timing_water_setting/{device_id}
 * 2.0定时用水开关 PUT	/api/v1/wechat/valve/timing_water_setting/simple/edit
 */

export const TimingWaterSetting = (device_id) => request({ method: 'get', url: `/api/v1/wechat/valve/timing_water_setting/${device_id}` })

export const PutSimpleEdit = (data) => request({ method: 'put', url: '/api/v1/wechat/valve/timing_water_setting/simple/edit', data })


//设备日志水路 /api/v1/wechat/account/valve/device/{device_id}/log

export const GetDeviceLog = (device_id, params) => request({ method: 'get', url: `/api/v1/wechat/account/valve/device/${device_id}/log`, params })

//设备日志水浸/v1/client/device/log/
export const GetwaterList = (device_name, params) => request({ method: 'get', url: `/api/v1/client/device/log/${device_name}`, params })

/**
 * 产品说明书模块
 * 1.产品说明书列表 GET	/api/v1/wechat/products/instructions
 * 2.产品说明书 GET	/api/v1/wechat/product/instruction/{product_id}
 * 3.其他推荐设备列表 GET	/api/v1/wechat/product/{product_id}/recommend
 * 4.购买地址GET	/api/v1/wechat/products/{product_id}/buyaddress
 * 5.设备百科GET	/api/v1/wechat/product/encyclopedia/{product_id}
 */


export const Getencyclopedia = (product_id) => request({ method: 'get', url: `/api/v1/wechat/product/encyclopedia/${product_id}` })

export const Getrecommend = (product_id, params) => request({ method: 'get', url: `/api/v1/wechat/product/${product_id}/recommend`, params })

export const Getbuyaddress = (product_id) => request({ method: 'get', url: `/api/v1/wechat/products/${product_id}/buyaddress` })

export const GetProductUrl = (product_id) => request({ method: 'get', url: `/api/v1/wechat/product/instruction/${product_id}` })


/**
 * 监护人通知模块
 * 1.查询监护人通知列表 GET	/api/v1/wechat/account/{product_flag}/{device_id}/guardian/notice
 * 2.添加监护人 POST	api/v1/wechat/account/guardian/notice
 * 3.删除监护人 DELETE	/api/v1/wechat/account/{account_id}/guardian/notice
 */


export const GetGuardianList = (product_flag, device_id) => request({ method: 'get', url: `/api/v1/wechat/account/${product_flag}/${device_id}/guardian/notice` })

export const PostNoticeList = (data) => request({ method: 'post', url: 'api/v1/wechat/account/guardian/notice', data })

export const deleteNoticeList = (account_id) => request({ method: 'delete', url: `/api/v1/wechat/account/${account_id}/guardian/notice` })




/**
 * 多人通知模块
 * 1.查询 多人通知列表 GET	/api/v1/wechat/account/{product_flag}/{device_id}/multi/notice
 * 2.添加 多人 POST	/api/v1/wechat/account/multi/notice
 * 3.删除 多人 DELETE	/api/v1/wechat/account/{account_id}/multi/notice
 */

export const GetManyPeopleList = (product_flag, device_id) => request({ method: 'get', url: `/api/v1/wechat/account/${product_flag}/${device_id}/multi/notice` });

export const PostManyPeopleList = (data) => request({ method: 'put', url: '/api/v1/wechat/account/multi/notice', data })

export const deleteManyPeopleList = (account_id) => request({ method: 'delete', url: `/api/v1/wechat/account/${account_id}/multi/notice` })


/**
 * 昵称修改PUT /api/v1/client/account/edit
 */

export const PutEditList = (data) => request({ method: 'put', url: '/api/v1/client/account/edit', data })


/**
 * 固件升级
 * 1.固件版本GET	/api/v1/wechat/account/product_flag/{product_flag}/device/{device_id}/version
 * 2.现在升级POST	/api/v1/wechat/account/product_flag/{product_flag}/device/{device_id}/ota
 */


export const GetVersion = (product_flag, device_id) => request({ method: 'get', url: `/api/v1/wechat/account/product_flag/${product_flag}/device/${device_id}/version` })

export const PostOtaDate = (product_flag, device_id) => request({ method: 'post', url: `/api/v1/wechat/account/product_flag/${product_flag}/device/${device_id}/ota` })

export const GetWaterVersion = (device_name) => request({ method: "get", url: `/api/v1/client/device/version/${device_name}` })

/**
 * 场景联动
 * 1.场景列表GET	/api/v1/wechat/account/device/scene/list
 * 2.场景联动开关PUT	/api/v1/wechat/account/device/scene/switch
 * 3.事件设备列表GET	/api/v1/wechat/scene/event/device/list
 * 4.事件列表GET	/api/v1/wechat/scene/{product_flag}/event/list
 * 5.执行设备列表GET	/api/v1/wechat/scene/execute/{device_id}/list
 * 6.执行列表GET	/api/v1/wechat/scene/{product_flag}/{event_id}/execute/list
 * 7.场景联动添加POST	/api/v1/wechat/account/device/scene/create
 * 8.场景联动更新PUT	/api/v1/wechat/account/device/scene/update
 * 9.场景联动删除DELETE	/api/v1/wechat/account/device/scene/{scene_linkage_id}/delete
 * 10.可联动设备列表GET	/api/v1/wechat/account/scene/default/{product_flag}/{device_id}/list
 * 11.可添加的场景联动GET	/api/v1/wechat/account/scene/recommend/list
 * 12.查询场景联动通知设置GET	/api/v1/wechat/account/scene_linkage/notice
 * 13.场景联动通知设置PUT	/api/v1/wechat/account/scene_linkage/notice_setup
 */

export const GetrecommendList = (params) => request({ method: 'get', url: '/api/v1/wechat/account/scene/recommend/list', params })

export const GetdeviceList = (params) => request({ method: 'get', url: '/api/v1/wechat/scene/event/device/list', params })

export const GetEventList = (product_flag, params) => request({ method: 'get', url: `/api/v1/wechat/scene/${product_flag}/event/list`, params })

export const GetExecuteList = (event_id, device_id, params) => request({ method: 'get', url: `/api/v1/wechat/scene/${event_id}/execute/${device_id}/list`, params })

export const GetExecute = (product_flag, event_id, params) => request({ method: 'get', url: `/api/v1/wechat/scene/${product_flag}/${event_id}/execute/list`, params })

export const PostCreate = (data) => request({ method: 'post', url: '/api/v1/wechat/account/device/scene/create', data })

export const GetSceneList = (params) => request({ method: 'get', url: '/api/v1/wechat/account/device/scene/list', params })

export const PutSceneSwitch = (data) => request({ method: 'put', url: '/api/v1/wechat/account/device/scene/switch', data })

export const DeleteRemove = (scene_linkage_id) => request({ method: 'delete', url: `/api/v1/wechat/account/device/scene/${scene_linkage_id}/delete` })

export const GetDefaltList = (product_flag, device_id) => request({ method: 'get', url: `/api/v1/wechat/account/scene/default/${product_flag}/${device_id}/list` })

/**
 * 1.解除设备报警设置
 * 2.设备水浸解绑
 */

export const PutStopAlarm = (product_flag, device_id, device_status) => request({ method: 'put', url: `/api/v1/client/product_flag/${product_flag}/device/${device_id}/type/${device_status}/remove_alarm` })

export const PutUnbind = (product_flag, device_id) => request({ method: "put", url: `/api/v1/wechat/account/product_flag/${product_flag}/device/${device_id}/unbind` })

/**
 * 1.设备异常日志
 * 2.意见反馈/api/v1/client/account/idea
 */
export const GetClentLog = (data) => request({
    method: 'get',
    url: '/api/v1/client/device/devicelog',
    data
})

export const PostClentIdea = (data) => request({
    method: 'post',
    url: '/api/v1/client/account/idea',
    data
})


/**
 * 1.绑定邮箱获取验证码/api/v1/client/email
 * 2.绑定邮箱POST	/api/v1/client/addEmail
 * 3.验证邮箱POST   /api/v1/client/oldEmail/verify
 */

export const BindEmailCode = (data) => request({ method: 'post', url: '/api/v1/client/email', data })

export const BindEmail = (data) => request({ method: "post", url: "/api/v1/client/addEmail", data })

export const BindoldEmail = (data) => request({ method: "post", url: "/api/v1/client/oldEmail/verify", data })

/**
 * 修改密码PUT   /api/v1/client/account/password
 */

export const PutPassword = (data) => request({ method: 'put', url: "/api/v1/client/account/password", data })

/**
 * 1.异常处理GET  /api/v1/user-help
 */

export const GetUserHelp = (params) => request({ method: 'get', url: '/api/v1/user-help', params })

/**
 * 1.开始准备GET	/api/v1/wechat/account/device/{device_id}/leakage_test/start
 * 2.测试条件GET    /api/v1/wechat/account/device/{device_id}/leakage_test/condition
 * 3.测试数据GET	/api/v1/wechat/account/device/{device_id}/leakage_test/data
 * 4.退出测试GET	/api/v1/wechat/account/device/{device_id}/leakage_test/end
 */
export const GetTestStart = (device_id) => request({ method: 'get', url: `/api/v1/wechat/account/device/${device_id}/leakage_test/start` })
export const GetTestCondition = (device_id) => request({ method: 'get', url: `/api/v1/wechat/account/device/${device_id}/leakage_test/condition` })
export const GetTestData = (device_id) => request({ method: 'get', url: `/api/v1/wechat/account/device/${device_id}/leakage_test/data` })
export const GetTestEnd = (device_id) => request({ method: 'get', url: `/api/v1/wechat/account/device/${device_id}/leakage_test/end` })


/**
 * 1.开始校准GET	/api/v1/wechat/account/device/{device_id}/calibration/{flow_flag}/start
 * 2.流量曲线GET	/api/v1/wechat/account/device/{device_id}/calibration/{flow_flag}/curve
 * 3.取消检测DELETE	/api/v1/wechat/account/device/{device_id}/calibration/{flow_flag}/restart
 * 
 */

export const GetStartMax = (device_id, flow_flag) => request({ method: 'get', url: `/api/v1/wechat/account/device/${device_id}/calibration/${flow_flag}/start` })
export const GetStartMin = (device_id, flow_flag) => request({ method: 'get', url: `/api/v1/wechat/account/device/${device_id}/calibration/${flow_flag}/start` })
export const DeleteRestart = (device_id, flow_flag) => request({ method: 'delete', url: `/api/v1/wechat/account/device/${device_id}/calibration/${flow_flag}/restart` })
export const GetCurveMax = (device_id) => request({ method: 'get', url: `/api/v1/wechat/account/device/${device_id}/calibration/0/curve` })
export const GetCurveMin = (device_id) => request({ method: 'get', url: `/api/v1/wechat/account/device/${device_id}/calibration/1/curve` })

/**
 * 水浸详情GET	/api/v1/wechat/alarm/{product_flag}/{device_id}/showpage
 * 水浸解除报警 /api/v1/client/product_flag/${that.data.StateList.product_flag}/device/${that.data.StateList.device_id}/type/${that.data.StateList.device_status}/remove_alarm
 *  */
export const GetWaterShowPage = (product_flag, device_id) => request({ method: 'get', url: `/api/v1/wechat/alarm/${product_flag}/${device_id}/showpage` })
export const PutRemoveAlarm = (product_flag, device_id, device_status) => request({ method: 'put', url: `/api/v1/client/product_flag/${product_flag}/device/${device_id}/type/${device_status}/remove_alarm` })

/**
 * 渗漏测试
 * 初始条件PUT	/api/v1/wechat/account/device/${this.data.device_id}/test_type/LEAKAGE/start
 * 开始准备GET	/api/v1/wechat/account/device/{device_id}/leakage_test/start
 * 进入测试条件GET	/api/v1/wechat/account/device/{device_id}/leakage_test/condition
 * 测试数据GET	/api/v1/wechat/account/device/{device_id}/leakage_test/data
 * 退出测试GET	/api/v1/wechat/account/device/{device_id}/leakage_test/end
 */
export const PutTestTypeStart = (device_id) => request({ method: 'put', url: `/api/v1/wechat/account/device/${device_id}/test_type/LEAKAGE/start` })
export const GetLeakageStart = (device_id) => request({ method: 'get', url: `/api/v1/wechat/account/device/${device_id}/leakage_test/start` })
export const GetCondition = (device_id) => request({ method: 'get', url: `/api/v1/wechat/account/device/${device_id}/leakage_test/condition` })
export const GetLeakageData = (device_id) => request({ method: 'get', url: `/api/v1/wechat/account/device/${device_id}/leakage_test/data` })
export const GetLeakageEnd = (device_id) => request({ method: 'get', url: `/api/v1/wechat/account/device/${device_id}/leakage_test/end` })

/**
 * 用水数据
 * 柱状图GET	/api/v1/wechat/account/device/{device_id}/statistic/water_data/date/{date}
 * 列表GET	/api/v1/wechat/account/device/{device_id}/statistic/water_data/date/{date}/time_id/{time_id}
 * 月用水趋势GET	/api/v1/wechat/account/device/{device_id}/statistic/water_use_trend/month
 * 月水路压力趋势GET	/api/v1/wechat/account/device/{device_id}/statistic/static_pressure_trend/month
 * 用水模型GET	/api/v1/wechat/account/device/{device_id}/waterway/status
 */

export const GetWaterDate = (device_id, date) => request({ method: 'get', url: `/api/v1/wechat/account/device/${device_id}/statistic/water_data/date/${date}` })
export const GetWaterTimeId = (device_id, date, time_id) => request({ method: 'get', url: `/api/v1/wechat/account/device/${device_id}/statistic/water_data/date/${date}/time_id/${time_id}` })
export const GetWaterUse = (device_id) => request({ method: 'get', url: `/api/v1/wechat/account/device/${device_id}/statistic/water_use_trend/month` })
export const GetStatistic = (device_id) => request({ method: 'get', url: `/api/v1/wechat/account/device/${device_id}/statistic/static_pressure_trend/month` })
export const GetWaterWay = (device_id) => request({ method: 'get', url: `/api/v1/wechat/account/device/${device_id}/waterway/status` })

/**
 * 首页弹窗
 * /v1/wechat/account/device/protection
 */

export const GetProtection = () => request({ method: 'get', url: '/api/v1/wechat/account/device/protection' })

/**
 * 生产检验
 * 初始化/api/v1/wechat/account/device/${device_id}/test_type/FACTORY/start`
 * 开始检测/api/v1/wechat/account/device/{device_id}/{check_type}/factory/start
 * 流量数据/api/v1/wechat/account/device/{device_id}/{check_type}/factory/data
 * 取消检测/api/v1/wechat/account/device/{device_id}/{check_type}/factory/cancel
 * 下一项/api/v1/wechat/account/device/{device_id}/{check_type}/factory/end
 * 结果提交/api/v1/wechat/account/device/{device_id}/factory/submit_result
 */
export const GetTestType = (device_id) => request({ method: 'put', url: `/api/v1/wechat/account/device/${device_id}/test_type/FACTORY/start` })
export const GetfactoryStart = (device_id, check_type) => request({ method: 'get', url: `/api/v1/wechat/account/device/${device_id}/${check_type}/factory/start` })
export const GetfactoryData = (device_id, check_type) => request({ method: 'get', url: `/api/v1/wechat/account/device/${device_id}/${check_type}/factory/data` })
export const GetfactoryCancel = (device_id, check_type) => request({ method: 'delete', url: `/api/v1/wechat/account/device/${device_id}/${check_type}/factory/cancel` })
export const GetfactoryEnd = (device_id, check_type) => request({ method: 'get', url: `/api/v1/wechat/account/device/${device_id}/${check_type}/factory/end` })
export const PutsubmitResult = (device_id, data) => request({ method: 'put', url: `/api/v1/wechat/account/device/${device_id}/factory/submit_result`, data })

/**
 * 用水模型
 * /v1/wechat/account/device/${this.data.device_id}/waterway/status
 */
export const GetWaterwayStatus = (device_id) => request({ method: 'get', url: `/api/v1/wechat/account/device/${device_id}/waterway/status` })

